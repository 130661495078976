import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import CountUp from 'react-countup';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import committeeWorkImage from '../assets/AboutUs/aboutus_committeework.jpg';
import culturalProgrammeImage from '../assets/AboutUs/aboutus_culturalProgramme.jpg';
import generalAssemblyImage from '../assets/AboutUs/aboutus_ga.jpg';
import teamImage from '../assets/AboutUs/aboutus_team.jpg';
import teambuildingImage from '../assets/AboutUs/aboutus_teambuilding.jpg';
import whatIsEYPImage from '../assets/AboutUs/aboutus_whateyp.jpg';

const AboutUsPage = () => {
  const { t } = useTranslation();
  
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <Box>
      {/* Introduction Section */}
      <Box py={8}>
        <Container maxWidth="xl">
          <Typography
            variant="h3"
            align="center"
            gutterBottom
            sx={{ fontWeight: 'bold', color: '#333' }}
          >
            {t('aboutUs.introduction.title')}
          </Typography>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src={whatIsEYPImage}
                alt={t('aboutUs.introduction.imageAlt')}
                sx={{
                  width: '100%',
                  borderRadius: '8px',
                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {t('aboutUs.introduction.paragraphs', { returnObjects: true }).map(
                (paragraph, idx) => (
                  <Typography
                    key={idx}
                    variant="h8"
                    color="textSecondary"
                    align="left"
                    paragraph
                  >
                    {paragraph}
                  </Typography>
                )
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Sessions Section */}
      <Box py={8}>
        <Container maxWidth="xl">
          <Typography
            variant="h3"
            align="center"
            gutterBottom
            sx={{ fontWeight: 'bold', color: '#333' }}
          >
            {t('aboutUs.sessions.title')}
          </Typography>
          {t('aboutUs.sessions.description', { returnObjects: true }).map(
            (desc, idx) => (
              <Typography
                key={idx}
                variant="h6"
                color="textSecondary"
                align="center"
                gutterBottom
              >
                {desc}
              </Typography>
            )
          )}
          <Grid container spacing={8} alignItems="center" mt={4}>
            {/* Teambuilding */}
            <Grid item xs={12} md={6}>
              <Box textAlign="center">
                <Box
                  component="img"
                  src={teambuildingImage}
                  alt={t('aboutUs.sessions.teambuilding.imageAlt')}
                  sx={{
                    width: '100%',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  }}
                />
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ mt: 4, fontWeight: 'bold', color: '#333' }}
                >
                  {t('aboutUs.sessions.teambuilding.title')}
                </Typography>
                <Typography variant="h6" color="textSecondary" gutterBottom>
                  {t('aboutUs.sessions.teambuilding.description')}
                </Typography>
              </Box>
            </Grid>
            {/* Committee Work */}
            <Grid item xs={12} md={6}>
              <Box textAlign="center">
                <Box
                  component="img"
                  src={committeeWorkImage}
                  alt={t('aboutUs.sessions.committeeWork.imageAlt')}
                  sx={{
                    width: '100%',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  }}
                />
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ mt: 4, fontWeight: 'bold', color: '#333' }}
                >
                  {t('aboutUs.sessions.committeeWork.title')}
                </Typography>
                <Typography variant="h6" color="textSecondary" gutterBottom>
                  {t('aboutUs.sessions.committeeWork.description')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={8} alignItems="center" mt={4}>
            {/* General Assembly */}
            <Grid item xs={12} md={6}>
              <Box textAlign="center">
                <Box
                  component="img"
                  src={generalAssemblyImage}
                  alt={t('aboutUs.sessions.generalAssembly.imageAlt')}
                  sx={{
                    width: '100%',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  }}
                />
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ mt: 4, fontWeight: 'bold', color: '#333' }}
                >
                  {t('aboutUs.sessions.generalAssembly.title')}
                </Typography>
                <Typography variant="h6" color="textSecondary" gutterBottom>
                  {t('aboutUs.sessions.generalAssembly.description')}
                </Typography>
              </Box>
            </Grid>
            {/* Cultural Programme */}
            <Grid item xs={12} md={6}>
              <Box textAlign="center">
                <Box
                  component="img"
                  src={culturalProgrammeImage}
                  alt={t('aboutUs.sessions.culturalProgramme.imageAlt')}
                  sx={{
                    width: '100%',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                  }}
                />
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ mt: 4, fontWeight: 'bold', color: '#333' }}
                >
                  {t('aboutUs.sessions.culturalProgramme.title')}
                </Typography>
                <Typography variant="h6" color="textSecondary" gutterBottom>
                  {t('aboutUs.sessions.culturalProgramme.description')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Statistics Section */}
      <Box py={8}>
        <Container maxWidth="xl">
          <Typography
            variant="h3"
            align="center"
            gutterBottom
            sx={{ fontWeight: 'bold', color: '#333' }}
          >
            {t('aboutUs.statistics.title')}
          </Typography>
          <Grid container spacing={4} justifyContent="center" mt={4}>
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={3}
                sx={{
                  padding: 4,
                  borderRadius: '8px',
                  textAlign: 'center',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': { transform: 'scale(1.05)' },
                }}
              >
                <Typography
                  variant="h4"
                  gutterBottom
                  color="primary"
                  sx={{ fontWeight: 'bold' }}
                >
                  <CountUp end={25000} duration={3} separator="," />+
                </Typography>
                <Typography variant="h6" color="textSecondary">
                  {t('aboutUs.statistics.participants')}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={3}
                sx={{
                  padding: 4,
                  borderRadius: '8px',
                  textAlign: 'center',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': { transform: 'scale(1.05)' },
                }}
              >
                <Typography
                  variant="h4"
                  gutterBottom
                  color="primary"
                  sx={{ fontWeight: 'bold' }}
                >
                  <CountUp end={35} duration={3} />+
                </Typography>
                <Typography variant="h6" color="textSecondary">
                  {t('aboutUs.statistics.yearsHistory')}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={3}
                sx={{
                  padding: 4,
                  borderRadius: '8px',
                  textAlign: 'center',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': { transform: 'scale(1.05)' },
                }}
              >
                <Typography
                  variant="h4"
                  gutterBottom
                  color="primary"
                  sx={{ fontWeight: 'bold' }}
                >
                  <CountUp end={40} duration={3} />
                </Typography>
                <Typography variant="h6" color="textSecondary">
                  {t('aboutUs.statistics.countries')}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper
                elevation={3}
                sx={{
                  padding: 4,
                  borderRadius: '8px',
                  textAlign: 'center',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': { transform: 'scale(1.05)' },
                }}
              >
                <Typography
                  variant="h4"
                  gutterBottom
                  color="primary"
                  sx={{ fontWeight: 'bold' }}
                >
                  <CountUp end={400} duration={3} />+
                </Typography>
                <Typography variant="h6" color="textSecondary">
                  {t('aboutUs.statistics.events')}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Audiences Section */}
      <Box py={8}>
        <Container maxWidth="xl">
          <Typography
            variant="h3"
            align="center"
            gutterBottom
            sx={{ fontWeight: 'bold', color: '#333' }}
          >
            {t('aboutUs.audiences.title')}
          </Typography>
          <Grid container spacing={8} alignItems="flex-start" mt={4}>
            {/* Students Slider */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ fontWeight: 'bold', color: '#333' }}
              >
                {t('aboutUs.audiences.students.title')}
              </Typography>
              <Slider {...carouselSettings}>
                {t('aboutUs.audiences.students.sliderItems', { returnObjects: true }).map(
                  (item, idx) => (
                    <Box key={idx} px={2}>
                      <Typography variant="h6" color="textSecondary" gutterBottom>
                        <strong>{item.title}</strong> {item.description}
                      </Typography>
                    </Box>
                  )
                )}
              </Slider>
            </Grid>
            {/* Teachers Slider */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ fontWeight: 'bold', color: '#333' }}
              >
                {t('aboutUs.audiences.teachers.title')}
              </Typography>
              <Slider {...carouselSettings}>
                {t('aboutUs.audiences.teachers.sliderItems', { returnObjects: true }).map(
                  (item, idx) => (
                    <Box key={idx} px={2}>
                      <Typography variant="h6" color="textSecondary" gutterBottom>
                        <strong>{item.title}</strong> {item.description}
                      </Typography>
                    </Box>
                  )
                )}
              </Slider>
            </Grid>
          </Grid>
          {/* Testimonials */}
          <Grid container spacing={4} mt={4}>
            {t('aboutUs.testimonials', { returnObjects: true }).map(
              (testimonial, idx) => (
                <Grid key={idx} item xs={12} md={6}>
                  <Paper
                    elevation={3}
                    sx={{
                      padding: 4,
                      borderRadius: '8px',
                      fontStyle: 'italic',
                      textAlign: 'center',
                    }}
                  >
                    <Typography variant="body1">
                      {testimonial.quote}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                      {testimonial.author}
                    </Typography>
                  </Paper>
                </Grid>
              )
            )}
          </Grid>
        </Container>
      </Box>

      {/* Team Section */}
      <Box py={8}>
        <Container maxWidth="xl">
          <Typography
            variant="h3"
            align="center"
            gutterBottom
            sx={{ fontWeight: 'bold', color: '#333' }}
          >
            {t('aboutUs.team.title')}
          </Typography>
          <Grid container spacing={4} justifyContent="center" mt={4}>
            <Grid item>
              <Box
                component="img"
                src={teamImage}
                alt={t('aboutUs.team.imageAlt')}
                sx={{ width: '100%', borderRadius: '8px' }}
              />
            </Grid>
            <Grid item>
              <Typography variant="h6" color="textSecondary" align="left" paragraph>
                {t('aboutUs.team.description')}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default AboutUsPage;
