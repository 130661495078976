import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import LoginIcon from '@mui/icons-material/Login';
import Switch from '@mui/material/Switch';

import logo from '../assets/logoMain.png'; // Your logo path
import { useAuth } from '../authContext';
import LanguageSwitcher from './LanguageSwitcher';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

function Navbar({ darkMode, setDarkMode }) {
  const { currentUser, signOut } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  const [mobileOpen, setMobileOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [openSearchDialog, setOpenSearchDialog] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSignOut = () => {
    signOut();
    navigate('/');
  };

  const handleReturnToMain = () => {
    navigate('/');
  };

  const handleUserDashboard = () => {
    navigate('/user-dashboard');
  };

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/search?query=${searchQuery}`);
    setOpenSearchDialog(false);
  };

  const menuItems = [
    { label: t('nav.aboutUs'), path: '/about-us' },
    { label: t('nav.news'), path: '/news' },
    { label: t('nav.events'), path: '/events' },
    { label: t('nav.partner'), path: '/partner' },
    { label: t('nav.understandingEurope'), path: '/ueaustria' },
    { label: t('nav.membership'), path: '/membership' },
    { label: t('nav.contact'), path: '/contact' },
  ];

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <List>
        {menuItems.map((item, index) => (
          <ListItem button component={RouterLink} to={item.path} key={index}>
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const logoWidth = isMobile ? '30vw' : '13vw';

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: '#fff', color: '#000' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="home"
            sx={{ mr: 2 }}
            onClick={handleReturnToMain}
          >
            <img
              src={logo}
              alt="Logo"
              style={{ width: logoWidth, height: 'auto', padding: 0, borderRadius: 0 }}
            />
          </IconButton>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'center',
            }}
          >
            {menuItems.map((item, index) => (
              <Button
                key={index}
                component={RouterLink}
                to={item.path}
                sx={{
                  color: '#000',
                  padding: { xs: '0.5rem', md: '1vw' },
                  textTransform: 'none',
                  fontWeight: 300,
                  fontSize: { xs: '0.9rem', md: '1vw' },
                }}
              >
                {item.label}
              </Button>
            ))}
          </Box>
          {isMobile ? (
            <>
              {/* <IconButton
                color="inherit"
                aria-label="search"
                onClick={() => setOpenSearchDialog(true)}
              >
                <SearchIcon />
              </IconButton> */}
              <IconButton color="inherit" aria-label="menu"   sx={{ ml: 'auto' }}  onClick={handleDrawerToggle} >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="right"
                open={mobileOpen}
                onClose={handleDrawerToggle}
              >
                {drawer}
              </Drawer>
            </>
          ) : (
            <>
              {/* <Search>
                <form onSubmit={handleSearch}>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder={t('nav.search')}
                    inputProps={{ 'aria-label': 'search' }}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </form>
              </Search> */}
              {/* {currentUser ? (
                <>
                  <IconButton color="inherit" onClick={handleUserDashboard}>
                    <AccountCircle />
                  </IconButton>
                  <Button
                    color="inherit"
                    onClick={handleSignOut}
                    sx={{ color: '#000', textTransform: 'none' }}
                  >
                    {t('nav.signOut')}
                  </Button>
                </>
              ) : (
                <IconButton color="inherit" component={RouterLink} to="/sign-in">
                  <LoginIcon />
                </IconButton>
              )} */}
              {/* <LanguageSwitcher /> */}
              {/* <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                <Switch checked={darkMode} onChange={() => setDarkMode(!darkMode)} />
              </Box> */}
            </>
          )}
        </Toolbar>
      </AppBar>

      <Dialog open={openSearchDialog} onClose={() => setOpenSearchDialog(false)} fullWidth>
        <DialogTitle>{t('nav.search')}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSearch}>
            <InputBase
              autoFocus
              placeholder={t('nav.search')}
              inputProps={{ 'aria-label': 'search' }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              fullWidth
              sx={{
                border: '1px solid #ccc',
                borderRadius: 1,
                padding: '0.5rem',
              }}
            />
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Navbar;
