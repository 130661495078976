import React from 'react';
import { Box, Card, CardActionArea, Container, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../authContext'; // adjust the import as needed
import CallIcon from '@mui/icons-material/Call';
import EventIcon from '@mui/icons-material/Event';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PersonIcon from '@mui/icons-material/Person';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import WebIcon from '@mui/icons-material/Web';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const { hasPermission } = useAuth(); // Assume this returns a function that checks permissions

  const adminItems = [
    { 
      label: 'Events', 
      icon: <EventIcon fontSize="large" />, 
      path: '/user-dashboard/admin/events', 
      description: 'Manage and edit upcoming events.',
      requiredPermission: 'manage_events'
    },
    { 
      label: 'News', 
      icon: <NewspaperIcon fontSize="large" />, 
      path: '/user-dashboard/admin/news', 
      description: 'Post and update news articles.',
      requiredPermission: 'manage_news'
    },
    { 
      label: 'Quotes', 
      icon: <FormatQuoteIcon fontSize="large" />, 
      path: '/user-dashboard/admin/quotes', 
      description: 'Curate inspirational quotes.',
      requiredPermission: 'manage_quotes'
    },
    { 
      label: 'Calls', 
      icon: <CallIcon fontSize="large" />, 
      path: '/user-dashboard/admin/calls', 
      description: 'Manage incoming calls and queries.',
      requiredPermission: 'manage_calls'
    },
    { 
      label: 'Contacts', 
      icon: <PersonIcon fontSize="large" />, 
      path: '/user-dashboard/admin/contacts', 
      description: 'Maintain contact lists and assign roles.',
      requiredPermission: 'manage_contacts'
    },
    { 
      label: 'Manage Pages', 
      icon: <WebIcon fontSize="large" />, 
      path: '/user-dashboard/admin/manage-pages', 
      description: 'Edit and restructure site pages.',
      requiredPermission: 'manage_content'
    },
    { 
      label: 'Role Management', 
      icon: <AdminPanelSettingsIcon fontSize="large" />, 
      path: '/user-dashboard/admin/role-management', 
      description: 'Assign and modify user roles.',
      requiredPermission: 'manage_roles'
    },
    { 
      label: 'Partners', 
      icon: <PersonIcon fontSize="large" />, 
      path: '/user-dashboard/admin/partner', 
      description: 'Manage and edit partner information.',
      requiredPermission: 'manage_partner' // Adjust if a different permission is needed
    },
  ];

  // Filter out items that the current user doesn’t have permission for.
  const visibleItems = adminItems.filter(item => hasPermission(item.requiredPermission));

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box mb={4} textAlign="center">
        <Typography variant="h3" gutterBottom>
          Admin Control Panel
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Select an item below to manage and edit content
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {visibleItems.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card 
              elevation={3} 
              sx={{ borderRadius: '12px', transition: 'transform 0.2s', '&:hover': { transform: 'translateY(-4px)' } }}
            >
              <CardActionArea 
                onClick={() => navigate(item.path)} 
                sx={{ p:2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              >
                <Box sx={{ mb: 2 }}>{item.icon}</Box>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  {item.label}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ mt:1, textAlign:'center' }}>
                  {item.description}
                </Typography>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default AdminDashboard;
