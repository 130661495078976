import React, { useEffect, useState } from 'react';
import {
  Container, Typography, Button, Box, IconButton, Dialog, DialogTitle, DialogContent,
  DialogActions, TextField, Snackbar, Alert, InputAdornment
} from '@mui/material';
import { Edit, Delete, Search, ContentCopy } from '@mui/icons-material';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { firestore } from '../../../firebase';
import { useAuth } from '../../../authContext';
import ErrorPage from '../../../components/ErrorPage';
import { DataGrid } from '@mui/x-data-grid';
import PartnerFormDialog from '../../../components/PartnerFormDialog';

const PartnersPage = () => {
  const { currentUser, hasPermission } = useAuth();
  const [isAllowed, setIsAllowed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [partners, setPartners] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredPartners, setFilteredPartners] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [initialPartner, setInitialPartner] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deletePartnerId, setDeletePartnerId] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isDuplicateMode, setIsDuplicateMode] = useState(false);
  const [pageSize, setPageSize] = useState(5);

  useEffect(() => {
    const fetchData = async () => {
      // Check if user is allowed to manage partners
      if (currentUser) {
        // Using our auth context function to check for either permission
        const allowed = hasPermission('manage_partner') || hasPermission('admin');
        setIsAllowed(allowed);
      }
      
      // Fetch partners data from Firestore
      try {
        const partnersSnap = await getDocs(collection(firestore, 'partners'));
        const partnersData = partnersSnap.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setPartners(partnersData);
        setFilteredPartners(partnersData);
      } catch (error) {
        console.error('Error fetching partners:', error);
      }
      
      setLoading(false);
    };

    fetchData();
  }, [currentUser, hasPermission]);

  useEffect(() => {
    if (!searchQuery) {
      setFilteredPartners(partners);
    } else {
      const lowerQuery = searchQuery.toLowerCase();
      setFilteredPartners(partners.filter(p => p.name.toLowerCase().includes(lowerQuery)));
    }
  }, [searchQuery, partners]);

  const handleOpenDialog = () => {
    setInitialPartner(null);
    setEditMode(false);
    setIsDuplicateMode(false);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setIsDuplicateMode(false);
    setOpenDialog(false);
  };

  const handleOpenDeleteDialog = (id) => {
    setDeletePartnerId(id);
    setDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(firestore, 'partners', deletePartnerId));
      setPartners(prev => prev.filter(partner => partner.id !== deletePartnerId));
      setFilteredPartners(prev => prev.filter(partner => partner.id !== deletePartnerId));
      setDeleteDialogOpen(false);
    } catch (err) {
      console.error('Error deleting partner:', err);
      setOpenSnackbar(true);
      setErrorMessage('Error deleting partner. Please try again.');
    }
  };

  const handleEdit = (partner) => {
    setInitialPartner(partner);
    setEditMode(true);
    setIsDuplicateMode(false);
    setOpenDialog(true);
  };

  const handleDuplicate = (partner) => {
    // Load the existing partner's data, but in duplicate mode we'll create a new record
    setInitialPartner(partner);
    setEditMode(true);
    setIsDuplicateMode(true);
    setOpenDialog(true);
  };

  const handleSavePartnerFinal = async (data) => {
    try {
      if (isDuplicateMode) {
        // Create a new partner record
        const newDoc = await addDoc(collection(firestore, 'partners'), data);
        const newPartner = { id: newDoc.id, ...data };
        setPartners(prev => [...prev, newPartner]);
        setFilteredPartners(prev => [...prev, newPartner]);
      } else if (initialPartner && editMode) {
        // Update the existing partner
        const partnerRef = doc(firestore, 'partners', initialPartner.id);
        await updateDoc(partnerRef, data);
        setPartners(prev => prev.map(p => p.id === initialPartner.id ? { ...p, ...data } : p));
        setFilteredPartners(prev => prev.map(p => p.id === initialPartner.id ? { ...p, ...data } : p));
      } else {
        // Create a new partner
        const newDoc = await addDoc(collection(firestore, 'partners'), data);
        const newPartner = { id: newDoc.id, ...data };
        setPartners(prev => [...prev, newPartner]);
        setFilteredPartners(prev => [...prev, newPartner]);
      }
      setIsDuplicateMode(false);
      handleCloseDialog();
    } catch (err) {
      console.error('Error creating/updating partner:', err);
      setOpenSnackbar(true);
      setErrorMessage('Error creating/updating partner. Please try again.');
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!isAllowed) {
    return <ErrorPage message="You do not have permission to manage partners." />;
  }

  // Added new column to display the "Vereinspartner" checkbox status
  const columns = [
    { field: 'name', headerName: 'Name', flex: 1, minWidth: 150 },
    {
      field: 'logoUrl',
      headerName: 'Logo',
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" justifyContent="center" width="100%">
          {params.value ? (
            <img src={params.value} alt="Logo" style={{ maxWidth: 50, maxHeight: 50 }} />
          ) : 'No Logo'}
        </Box>
      )
    },
    {
      field: 'isVereinPartner',
      headerName: 'Vereinspartner',
      flex: 0.5,
      minWidth: 120,
      renderCell: (params) => (params.value ? 'Yes' : 'No')
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 180,
      sortable: false,
      renderCell: (params) => {
        const partner = params.row;
        return (
          <Box display="flex" gap={1}>
            <IconButton onClick={() => handleEdit(partner)}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => handleDuplicate(partner)}>
              <ContentCopy />
            </IconButton>
            <IconButton onClick={() => handleOpenDeleteDialog(partner.id)}>
              <Delete />
            </IconButton>
          </Box>
        );
      }
    }
  ];

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Manage Partners
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <TextField
          variant="outlined"
          placeholder="Search by name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            )
          }}
        />
        <Button variant="contained" color="primary" onClick={handleOpenDialog}>
          Add Partner
        </Button>
      </Box>
      <div style={{ height: 600, width: '100%' }}>
        <DataGrid
          rows={filteredPartners}
          columns={columns}
          pagination
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 20]}
        />
      </div>

      <PartnerFormDialog
        open={openDialog}
        editMode={editMode || isDuplicateMode}
        initialPartner={initialPartner}
        onClose={() => {
          setIsDuplicateMode(false);
          setEditMode(false);
          handleCloseDialog();
        }}
        onSave={handleSavePartnerFinal}
      />

      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this partner?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default PartnersPage;
