import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BarChartIcon from '@mui/icons-material/BarChart';
import CallIcon from '@mui/icons-material/Call';
import EventIcon from '@mui/icons-material/Event';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import HomeIcon from '@mui/icons-material/Home';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PersonIcon from '@mui/icons-material/Person';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { Box, IconButton, List, ListItem, ListItemIcon, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../authContext';
import { CreditCard } from '@mui/icons-material';

const SidebarIcon = styled(ListItemIcon, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ theme, active }) => ({
  backgroundColor: active ? theme.palette.primary.main : 'transparent',
  color: active ? theme.palette.common.white : theme.palette.text.primary,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
  transition: 'background-color 0.3s, color 0.3s',
  padding: theme.spacing(1),
  borderRadius: '50%',
  marginBottom: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const SidebarContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})(({ theme, isOpen }) => ({
  position: 'fixed',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 1200,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  borderRadius: '16px',
  transition: 'width 0.3s ease-in-out, height 0.3s ease-in-out, left 0.3s ease-in-out',
  width: isOpen ? '240px' : '60px',
  height: 'auto',
  overflow: 'visible',
}));

const SidebarItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})(({ theme, isOpen }) => ({
  justifyContent: isOpen ? 'flex-start' : 'center',
  paddingLeft: isOpen ? theme.spacing(2) : '0',
  paddingRight: isOpen ? theme.spacing(2) : '0',
  textAlign: isOpen ? 'left' : 'center',
  transition: 'padding 0.3s ease-in-out, transform 0.3s ease-in-out',
  transform: isOpen ? 'translateX(0)' : 'translateX(0)',
  width: '100%',
}));

const ToggleButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isOpen',
})(({ theme, isOpen }) => ({
  position: 'absolute',
  right: isOpen ? '-15px' : '-15px',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  borderRadius: '50%',
  width: '30px',
  height: '30px',
  boxShadow: theme.shadows[3],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'transform 0.3s ease-in-out',
  padding: 0,
}));


const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(() => localStorage.getItem('sidebarState') === 'true');
  const navigate = useNavigate();
  const location = useLocation();
  const { userPermissions } = useAuth();

  const toggleSidebar = () => {
    const newState = !isOpen;
    setIsOpen(newState);
    localStorage.setItem('sidebarState', newState);
  };

  useEffect(() => {
    const savedState = localStorage.getItem('sidebarState') === 'true';
    setIsOpen(savedState);
  }, []);


  console.log('userPermissions'+userPermissions);
  const menuItems = [
    { label: 'Home', icon: <HomeIcon />, path: '/user-dashboard/' },
    { label: 'Profile', icon: <PersonIcon />, path: '/user-dashboard/profile' },
    { label: 'Statistics', icon: <BarChartIcon />, path: '/user-dashboard/statistics' },
    ...(userPermissions.some(permission => ['manage_news', 'manage_events', 'manage_roles', 'admin', 'admin_dashboard', 'manage_partner', 'manage_content', "admin"].includes(permission)) ? [
      { 
        label: 'Admin', 
        icon: <AdminPanelSettingsIcon />, 
        path: '/user-dashboard/admin', 
        subItems: [
          ...(userPermissions.includes('manage_events') ? [{ label: 'Events', icon: <EventIcon />, path: '/user-dashboard/admin/events' }] : []),
          ...(userPermissions.includes('manage_news') ? [{ label: 'News', icon: <NewspaperIcon />, path: '/user-dashboard/admin/news' }] : []),
          ...(userPermissions.includes('manage_quotes') ? [{ label: 'Quotes', icon: <FormatQuoteIcon />, path: '/user-dashboard/admin/quotes' }] : []),
          ...(userPermissions.includes('manage_calls') ? [{ label: 'Calls', icon: <CallIcon />, path: '/user-dashboard/admin/calls' }] : []),
          ...(userPermissions.includes('manage_contacts') ? [{ label: 'Contacts', icon: <PersonIcon />, path: '/user-dashboard/admin/contacts' }] : []),
          ...(userPermissions.includes('manage_partner') ? [{ label: 'Partner', icon: <CreditCard />, path: '/user-dashboard/admin/partner' }] : []),
        ]
      }
    ] : []),
  ];

  return (
    <SidebarContainer isOpen={isOpen}>
      <ToggleButton onClick={toggleSidebar} isOpen={isOpen}>
        {isOpen ? <ArrowBackIosIcon style={{ margin: 'auto', marginLeft: '0.5vw' }} /> : <ArrowForwardIosIcon style={{ margin: 'auto' }} />}
      </ToggleButton>
      <List>
        {menuItems.map((item, index) => (
          <React.Fragment key={index}>
            <Tooltip title={!isOpen ? item.label : ''} placement="right">
              <SidebarItem onClick={() => navigate(item.path)} isOpen={isOpen}>
                <SidebarIcon active={location.pathname === item.path}>
                  {item.icon}
                </SidebarIcon>
                {isOpen && <Typography variant="caption" sx={{ textAlign: 'center' }}>{item.label}</Typography>}
              </SidebarItem>
            </Tooltip>
            {isOpen && item.subItems && item.subItems.map((subItem, subIndex) => (
              <Tooltip title={!isOpen ? subItem.label : ''} placement="right" key={subIndex}>
                <SidebarItem onClick={() => navigate(subItem.path)} sx={{ pl: 4 }} isOpen={isOpen}>
                  <SidebarIcon active={location.pathname === subItem.path}>
                    {subItem.icon}
                  </SidebarIcon>
                  {isOpen && <Typography variant="caption" sx={{ textAlign: 'center' }}>{subItem.label}</Typography>}
                </SidebarItem>
              </Tooltip>
            ))}
          </React.Fragment>
        ))}
      </List>
    </SidebarContainer>
  );
};

export default Sidebar;
