import { createContext, useContext, useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged, signOut as firebaseSignOut } from 'firebase/auth';
import { getDoc, doc, getFirestore } from 'firebase/firestore';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userPermissions, setUserPermissions] = useState([]);

  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const fetchUserRolesAndPermissions = async (user) => {
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const roles = userDoc.data().roles || [];
          const permissionsSet = new Set();

          for (const role of roles) {
            const roleDoc = await getDoc(doc(db, 'roles', role));
            if (roleDoc.exists()) {
              const roleData = roleDoc.data();
              (roleData.permissions || []).forEach(permission => permissionsSet.add(permission));
            }
          }

          setUserPermissions([...permissionsSet]); // Store user permissions as an array
        }
      }
      setLoading(false);
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      if (user) {
        fetchUserRolesAndPermissions(user);
      } else {
        setUserPermissions([]);
        setLoading(false);
      }
    });

    return unsubscribe;
  }, [auth, db]);

  // Define the signOut function
  const signOut = async () => {
    await firebaseSignOut(auth);
    setCurrentUser(null);  // Optional: Update state after sign-out
    setUserPermissions([]);  // Clear permissions on sign-out
  };

    // Add the hasPermission function
    const hasPermission = (permission) => {
      // Global override: if user has 'admin' permission, allow everything.
      if (userPermissions.includes('admin')) return true;
      return userPermissions.includes(permission);
    };

  return (
    <AuthContext.Provider value={{ currentUser, userPermissions, signOut, hasPermission }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
