import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Snackbar,
  Alert,
  Box,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const PartnerFormDialog = ({ open, onClose, onSave, initialPartner, editMode }) => {
  const [name, setName] = useState('');
  const [logo, setLogo] = useState(null);
  const [logoUrl, setLogoUrl] = useState('');
  const [link, setLink] = useState('');
  const [isVereinPartner, setIsVereinPartner] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (initialPartner) {
      setName(initialPartner.name || '');
      setLogo(null);
      setLogoUrl(initialPartner.logoUrl || '');
      setIsVereinPartner(initialPartner.isVereinPartner || false);
      setLink(initialPartner.website || '');
    } else {
      setName('');
      setLogo(null);
      setLogoUrl('');
      setIsVereinPartner(false);
      setLink('');
    }
  }, [initialPartner]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setLogo(file);
    } else {
      setOpenSnackbar(true);
      setErrorMessage('Invalid file type. Please select an image.');
    }
  };

  const handleImageUpload = async () => {
    if (!logo) return logoUrl; // If no new logo is selected, use the existing one
    const storage = getStorage();
    const uniqueName = `${Date.now()}-${logo.name}`;
    const storageRef = ref(storage, `partner-logos/${uniqueName}`);
    await uploadBytes(storageRef, logo);
    const url = await getDownloadURL(storageRef);
    setLogoUrl(url);
    return url;
  };

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const validateForm = () => {
    if (!name) {
      setOpenSnackbar(true);
      setErrorMessage('Name is required');
      return false;
    }
    if (link && !isValidUrl(link)) {
      setOpenSnackbar(true);
      setErrorMessage('Please enter a valid URL for the link');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    try {
      const finalLogoUrl = await handleImageUpload();
      onSave({
        name,
        logoUrl: finalLogoUrl || '',
        isVereinPartner,
        website: link
      });
    } catch (err) {
      console.error('Error saving partner:', err);
      setOpenSnackbar(true);
      setErrorMessage('Error saving partner. Please try again.');
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{editMode ? 'Edit Partner' : 'Create Partner'}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Partner Name"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ mb: 2 }}
          />

          {/* Checkbox for "Ist ein Vereinspartner" */}
          <FormControlLabel
            control={
              <Checkbox
                checked={isVereinPartner}
                onChange={(e) => setIsVereinPartner(e.target.checked)}
                color="primary"
              />
            }
            label="Ist ein Vereinspartner"
            sx={{ mb: 2 }}
          />

          <TextField
            label="Link"
            variant="outlined"
            fullWidth
            value={link}
            onChange={(e) => setLink(e.target.value)}
            sx={{ mb: 2 }}
          />

          <Typography variant="h6">Upload Partner Logo (Optional)</Typography>
          <Button variant="outlined" component="label" sx={{ mr: 2 }}>
            Upload Logo
            <input type="file" accept="image/*" hidden onChange={handleFileChange} />
          </Button>

          {(logo || logoUrl) && (
            <Box mt={2} textAlign="center">
              <img
                src={logo ? URL.createObjectURL(logo) : logoUrl}
                alt="Partner Logo"
                style={{ maxWidth: '100px', maxHeight: '100px' }}
              />
              <Button
                variant="text"
                color="error"
                onClick={() => {
                  setLogo(null);
                  setLogoUrl('');
                }}
              >
                Remove Logo
              </Button>
            </Box>
          )}

          <DialogActions sx={{ mt: 2 }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="submit" variant="contained" color="primary">
              {editMode ? 'Save Changes' : 'Create'}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>

      <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default PartnerFormDialog;
