import React, { useEffect, useState, useRef } from 'react';
import { 
  Box, Button, Card, CardMedia, CircularProgress, Container, Grid, Link as MuiLink,Pagination, Paper, Typography 
} from '@mui/material';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import { InstagramEmbed } from 'react-social-media-embed';
import { useConsent } from '../context/ConsentContext';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import rough from 'roughjs/bundled/rough.esm';
import ParterFoto1 from '../assets/partner_picture/kaffeetschi.jpg';
import ParterFoto2 from '../assets/partner_picture/porr.jpg';
import ParterFoto3 from '../assets/partner_picture/stroeck.jpg';
import ParterFoto4 from '../assets/partner_picture/partner_linz24.jpg';
import ParterFoto5 from '../assets/partner_picture/tpa.jpg';
import ParterFoto6 from '../assets/partner_picture/bmlv.jpg';


// RoughUnderline component using Rough.js to draw a hand-drawn underline.
const RoughUnderline = ({ width, height, color, strokeWidth }) => {
  const svgRef = useRef(null);

  useEffect(() => {
    const svg = svgRef.current;
    if (!svg) return;
    // Clear any previous drawing
    while (svg.firstChild) {
      svg.removeChild(svg.firstChild);
    }
    const rc = rough.svg(svg);
    // Draw a line from left to right, centered vertically.
    const node = rc.line(0, height / 2, width, height / 2, { 
      stroke: color, 
      strokeWidth: strokeWidth || 3,
      roughness: 2
    });
    svg.appendChild(node);
  }, [width, height, color, strokeWidth]);

  return <svg ref={svgRef} width={width} height={height} style={{ display: 'block' }} />;
};

const PartnersPage = () => {
  const { consent, openConsentDialog } = useConsent();
  const [partners, setPartners] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [marketingNumbers, setMarketingNumbers] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 6;
  const [numbersVisible, setNumbersVisible] = useState(false);

  useEffect(() => {
    const fetchPartners = async () => {
      const db = getFirestore();
      const partnersSnapshot = await getDocs(collection(db, 'partners'));
      const partnersData = partnersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      // Only include partners where isVereinPartner is truthy
      const vereinPartners = partnersData.filter(partner => partner.isVereinPartner);
      setPartners(vereinPartners);
    };

    const fetchGalleryImages = async () => {
      const galleryData = [
        { id: 1, url: ParterFoto1, alt: 'Partner Image 1' },
        { id: 2, url: ParterFoto2, alt: 'Partner Image 2' },
        { id: 3, url: ParterFoto3, alt: 'Partner Image 3' },
        { id: 4, url: ParterFoto4, alt: 'Partner Image 4' },
        { id: 5, url: ParterFoto5, alt: 'Partner Image 5' },
        { id: 6, url: ParterFoto6, alt: 'Partner Image 5' },

      ];
      setGalleryImages(galleryData);
    };

    const fetchMarketingNumbers = async () => {
      // In a real scenario, fetch this data from your API or Firestore
      const marketingData = {
        instagram: 1746,
        facebook: 4541,
        tiktok: 25126,
        outreach: 50000,
      };
      setMarketingNumbers(marketingData);
    };

    fetchPartners();
    fetchGalleryImages();
    fetchMarketingNumbers();
  }, []);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const displayedImages = galleryImages.slice(
    (currentPage - 1) * imagesPerPage,
    currentPage * imagesPerPage
  );

  return (
    <Box 
      sx={{ 
        overflow: 'auto', 
        minHeight: '100vh',
        py: { xs: 4, md: 8 }      }}
    >
      <Container maxWidth="lg" sx={{ pt: { xs: 8, md: 12 }, pb: { xs: 4, md: 8 }}}>
        
        <Box textAlign="center" py={5}>
          <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold', fontSize: { xs: '2rem', md: '3rem' } }}>
            Our Partners
          </Typography>
          <Typography variant="h6" color="textSecondary" gutterBottom sx={{ fontSize: { xs: '1rem', md: '1.25rem' } }}>
            We are proud to feature our esteemed Vereinspartner.
          </Typography>
        </Box>

        {/* Partner Cards Section with Yellow Wave Decorations */}
        <Box sx={{ position: 'relative', mb: 8, pt: { xs: 4, md: 6 } }}>

          {/* Partner Cards */}
          <Grid container spacing={4} justifyContent="center" sx={{ position: 'relative', zIndex: 1 }}>
            {partners.map((partner) => (
              <Grid item xs={12} sm={6} md={4} key={partner.id}>
                {partner.website ? (
                  <MuiLink
                    href={partner.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ textDecoration: 'none', display: 'block' }} // display block to cover the full card
                  >
                    <Paper
                      elevation={6}
                      sx={{
                        textAlign: 'center',
                        p: 4,
                        borderRadius: 3,
                        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s',
                        '&:hover': { transform: 'scale(1.05)', boxShadow: 10 },
                        bgcolor: '#fff',
                        cursor: 'pointer' // show pointer to indicate clickability
                      }}
                    >
                      <CardMedia
                        component="img"
                        height="200"
                        image={partner.logoUrl}
                        alt={partner.name}
                        sx={{ objectFit: 'contain', mb: 2 }}
                      />
                      <Box sx={{ position: 'relative', display: 'inline-block' }}>
                        <Typography variant="h6" sx={{ fontWeight: 'medium' }}>
                          {partner.name}
                        </Typography>
                        <RoughUnderline width={300} height={10} color="#fdd835" strokeWidth={3} />
                      </Box>
                    </Paper>
                  </MuiLink>
                ) : (
                  <Paper
                    elevation={6}
                    sx={{
                      textAlign: 'center',
                      p: 4,
                      borderRadius: 3,
                      transition: 'transform 0.3s ease-in-out, box-shadow 0.3s',
                      '&:hover': { transform: 'scale(1.05)', boxShadow: 10 },
                      bgcolor: '#fff'
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="200"
                      image={partner.logoUrl}
                      alt={partner.name}
                      sx={{ objectFit: 'contain', mb: 2 }}
                    />
                    <Box sx={{ position: 'relative', display: 'inline-block' }}>
                      <Typography variant="h6" sx={{ fontWeight: 'medium' }}>
                        {partner.name}
                      </Typography>
                      <RoughUnderline width={300} height={10} color="#fdd835" strokeWidth={3} />
                    </Box>
                  </Paper>
                )}

              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Marketing Numbers Section */}
        <Box mt={8} textAlign="center">
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', fontSize: { xs: '1.75rem', md: '2.5rem' } }}>
            EYP in Numbers
          </Typography>
          <Typography variant="h6" color="textSecondary" gutterBottom sx={{ fontSize: { xs: '1rem', md: '1.25rem' } }}>
            Our impact across social platforms and outreach.
          </Typography>
          <VisibilitySensor onChange={(isVisible) => { if (isVisible) setNumbersVisible(true); }} delayedCall>
            <Grid container spacing={4} justifyContent="center" mt={4}>
              {[
                { label: 'Instagram', value: marketingNumbers.instagram, suffix: 'Followers' },
                { label: 'Facebook', value: marketingNumbers.facebook, suffix: 'Accounts Reached' },
                { label: 'TikTok', value: marketingNumbers.tiktok, suffix: 'Accounts Reached' },
                { label: 'Outreach', value: marketingNumbers.outreach, suffix: 'People Reached' }
              ].map((item, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Paper elevation={6} sx={{ p: 4, textAlign: 'center', borderRadius: 3, bgcolor: '#fff' }}>
                    <Typography variant="h5">{item.label}</Typography>
                    <Typography variant="h4" color="primary">
                      {numbersVisible ? <CountUp end={item.value} duration={2} /> : '0'}
                    </Typography>
                    <Typography variant="body1">{item.suffix}</Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </VisibilitySensor>
        </Box>

        {/* Partner Gallery Section */}
        <Box mt={8} textAlign="center">
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', fontSize: { xs: '1.75rem', md: '2.5rem' } }}>
            Partner Gallery
          </Typography>
          <Typography variant="h6" color="textSecondary" gutterBottom sx={{ fontSize: { xs: '1rem', md: '1.25rem' } }}>
            A showcase of our partners in action.
          </Typography>
          <Grid container spacing={4} justifyContent="center" alignItems="center">
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                {displayedImages.map((image) => (
                  <Grid item xs={6} sm={4} md={4} key={image.id}>
                    <Card
                      sx={{
                        boxShadow: 4,
                        borderRadius: 2,
                        overflow: 'hidden',
                        transition: 'transform 0.3s ease',
                        '&:hover': { transform: 'scale(1.02)' },
                      }}
                    >
                      <CardMedia 
                        component="img" 
                        image={image.url} 
                        alt={image.alt} 
                        sx={{ width: '100%', height: '10vw', objectFit: 'cover' }} 
                        />
                    </Card>
                  </Grid>
                ))}
              </Grid>
              <Box mt={2}>
                <Pagination
                  count={Math.ceil(galleryImages.length / imagesPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ width: '100%', maxWidth: '20vw', mx: 'auto' }}>
                {consent.thirdParty ? (
                  <InstagramEmbed
                    url="https://www.instagram.com/p/C7WeHC4N2Ta/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                    width="100%"
                    hideCaption={false}
                    containerTagName="div"
                    protocol=""
                    injectScript
                    onLoading={() => <CircularProgress />}
                    onSuccess={() => console.log('Loaded')}
                    onAfterRender={() => console.log('Rendered')}
                    onFailure={(error) => console.error('Error:', error)}
                  />
                ) : (
                  <Box>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                      Instagram content is hidden until you accept third-party cookies.
                    </Typography>
                    <Button variant="contained" onClick={openConsentDialog}>
                      Customize Cookie Settings
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default PartnersPage;
